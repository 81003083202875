<template>
  <div class="container">
    <certificate-guide :title="$t('header.menu.others')" :type="type" :code-type="codeType" />
  </div>
</template>

<script>
import CertificateGuide from '../components/CertificateGuide'
export default {
  name: 'Index',
  components: { CertificateGuide },
  data() {
    return {
      type: 'OTHER',
      codeType: 'CERT_CATALOG_OTHER'
    }
  }
}
</script>

<style scoped>

</style>
